<template>
  <div />
</template>

<script>
import { firebase } from '@/firebase/app'
import queryString from 'query-string'

export default {
  mounted () {
    const query = queryString.parse(window.location.search)

    const idToken = query.id_token
    if (window.todesktop && idToken) {
      const credential = firebase.auth.GoogleAuthProvider.credential(idToken)
      firebase.auth().signInWithCredential(credential)
    }
  }
}
</script>
